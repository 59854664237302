.transition {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    z-index: 9;
    pointer-events: none;

    &.transition-goo-work path {
        fill: $dark-red !important;
    }

    // Flip about transition for variety
    &.transition-goo-about {
        transform: translate(-50%, -50%) scaleX(-1);

        path {
            fill: $purple !important;
        }
    }

    &.transition-goo-contact path {
        fill: $other-blue !important;
    }

    @media (orientation: portrait) {
        &.transition-goo-work,
        &.transition-goo-contact {
            width: 100vh;
            height: 100vw;
            transform: translate(-50%, -50%) rotate(90deg);
        }

        &.transition-goo-about {
            width: 100vh;
            height: 100vw;
            transform: translate(-50%, -50%) rotate(90deg) scaleX(-1);
        }
    }
}

header {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 15px;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(255,255,255,0);
    transition: transform 1s $snappy;

    .container {
        position: relative;
        z-index: 2;
    }

    .bg {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 1px;
        background-color: rgba(255,255,255,0);
        transition: background-color 0.15s;
    }

    &.hide {
        transform: translateY(calc(-100% - 40px));
        transition: transform 1s $snappy, background-color 0s linear 1s;

        .bg {
            background-color: rgba(255,255,255,1);
            transition: background-color 0.15s linear 1s;
        }

        ul a,
        ul a:hover {
            transition: color 0.25s linear 0.5s;
            color: $black;
        }
    }

    &.show {
        transform: translate3d(0, -16px, 0);

        .bg {
            background-color: rgba(255,255,255,1);
        }

        ul a,
        ul a:hover {
            color: $black;
        }
    }

    &.dark {
        ul a,
        ul a:hover {
            color: $black;
        }
    }

    &.close {
        transform: translate3d(0, -30px, 0);

        ul a,
        ul a:hover {
            opacity: 0;
        }
    }

    a.logo {
        display: block;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-right: 10px;

        img {
            display: block;
        }
    }

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;

        a {
            display: block;
            padding: 10px 6px;
            text-transform: uppercase;
            font-weight: $medium;
            font-size: 16px;
            color: $white;
            transition: color 0.25s, opacity 0.5s;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    @include sm {
        a.logo {
            width: 50px;
            height: 50px;
        }

        ul a {
            padding: 10px 20px;
        }
    }

    @include md {
        top: 30px;

        &.show {
            transform: translate3d(0, -31px, 0);
        }

        &.close {
            transform: translate3d(0, -40px, 0);
        }
    }
}
