@mixin sm {
    @media (min-width: map-get($grid-breakpoints, 'sm')) { @content; }
}

@mixin md {
    @media (min-width: map-get($grid-breakpoints, 'md')) { @content; }
}

@mixin lg {
    @media (min-width: map-get($grid-breakpoints, 'lg')) { @content; }
}

@mixin xl {
    @media (min-width: map-get($grid-breakpoints, 'xl')) { @content; }
}

@mixin fill {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.fill { @include fill; }

@mixin cover {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cover { @include cover; }

@mixin contain {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cover { @include cover; }

@mixin debug {
    outline: 1px solid red;
}

@function rem( $size ) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}
