section.contact {
    min-height: 100vh;
    background-color: $other-blue;
    color: $white;

    a {
        color: $mid-yellow;
    }

    .contact-content {
        opacity: 0;
    }
}
