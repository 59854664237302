@import 'variables';
@import 'mixins';

@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

@import 'typography';
@import 'header';
@import 'footer';
@import 'home';
@import 'work';
@import 'about';
@import 'contact';

html,
body {
    background-color: $black;
}

body {
    min-height: calc(100vh + 1px);
    padding-bottom: 1px;
}

img,
video {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-wrap {
    position: relative;
    overflow: hidden;
    background-color: $gray-100;

    img {
        @include fill;
        display: block;
        opacity: 0;
        transform: scale(1.1);
        transition: opacity 0.5s, transform 0.5s $snappy;

        &.lazyloaded {
            opacity: 1;
            transform: scale(1);
        }
    }
}

section.about {
    color: $white;

    .divider {
        display: none;
        margin: 0 10px;
        color: #333;
    }

    .address {
        display: block;
        color: #666;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        color: #666;
    }

    @include md {
        ul {
            float: right;
        }
    }

    @include lg {
        .divider {
            display: inline-block;
        }

        .address {
            display: inline-block;
        }
    }
}

#barba-wrapper {
    position: relative;
    z-index: 2;
}

.scroll-sentinel {
    display: none;

    @include lg {
        display: block;
        margin-bottom: 300px;
    }
}

.scroll-wrap {
    margin-bottom: 300px;

    @include lg {
        position: fixed;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100vw;
        height: 0;
        margin-bottom: 0;
    }
}

.animate,
.animate-children > * {
    opacity: 0;
    transform: translateY(60px);
}
