$white:         #FFFFFF;
$light-grey:    #CCCCCC;
$grey:          #5B5B5B;
$dark-grey:     #444444;
$black:         #000000;

$brightest-blue:#29BAF5;
$brighter-blue: #29BBF6;
$bright-blue:   #00BAE7;
$chill-blue:    #146FA4;
$other-blue:    #02304B;

$bright-yellow: #EDF000;
$mid-yellow:    #DEE02D;
$chill-yellow:  #C5BB41;

$deep-red:      #AE2350;
$dark-red:      #8F1B40;
$purple:        #6C136F;

$theme-colors: (
    primary:          $bright-blue,
    secondary:        $purple,
    light:            $light-grey,
    dark:             $black
);

$logo_width:    470px;
$logo_height:   163.66px;

// Transitions
$snappy:              cubic-bezier(0.42, 0, 0.05, 0.99);
$ease-in:             cubic-bezier(0.73, 0.08, 1, 1);
$ease-out:            cubic-bezier(0.1, 0.85, 0.35, 0.99);
$ease-out-back:       cubic-bezier(0.29, 0.97, 0, 1.85);
$ease-out-back-easy:  cubic-bezier(0, 1.35, 0.77, 0.99);

// Fonts
$light:         300;
$regular:       400;
$medium:        500;
$bold:          700;
$heavy:         900;
$sans:          'effra', sans-serif;
$pixel:         'forward', monospace;

$headings-margin-bottom:  1rem;

$grid-gutter-width: 50px;

$spacer: 30px;
$spacers: (
    0: 0,
    1: ($spacer * 1),
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    6: ($spacer * 6),
    7: ($spacer * 7),
    8: ($spacer * 8),
    9: ($spacer * 9),
    10: ($spacer * 10),
);
