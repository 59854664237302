footer {
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 300px;
    background-color: $black;

    a {
        color: $white;

        &:hover {
            color: $grey;
        }
    }

    .container {
        max-width: 960px;
    }

    .footer-nav {
        display: flex;
        padding: 0;
        list-style: none;

        li {
            margin-right: 1rem;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .footer-social {
        display: flex;
        padding: 0;
        list-style: none;
        font-size: 1.5rem;

        li {
            margin-right: 1rem;
        }
    }

    .email {
        line-height: 1;
        font-weight: $medium;
        font-size: 1.25rem;
        color: $grey;
    }

    .address {
        display: flex;
        align-items: center;
        line-height: 1;
        font-weight: $medium;
        font-size: 1rem;
        color: $grey;

        svg.icon {
            margin: 0 1rem 0 0;
            font-size: 2rem;
        }
    }

    @include md {
        .footer-nav li {
            margin-right: 2rem;
        }

        .footer-social {
            font-size: 2rem;
        }

        .footer-contact {
            float: right;
        }
    }
}
