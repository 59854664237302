section.about {
    min-height: calc(100vh - 300px);
    position: relative;
    background-color: $purple;

    .bg {
        min-height: 100vh;
        @include fill;
        z-index: 1;
        background-color: $purple;
    }

    .about-content {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 200px 0 0;
        overflow: hidden;
        color: $white;

        h2,
        h3 {
            color: $mid-yellow;
        }

        h1, h2, h3, p {
            > div {
                overflow: hidden;
            }
        }
    }

    @include lg {
        .about-content {
            padding: 200px 0;
        }

        .container {
            width: calc(100% - 250px);
        }
    }

    @include xl {
        .container {
            max-width: 960px;
        }
    }
}

// .dot-transition is used on About, Team, and Culture pages
.dot-transition {
    // @include debug;
    position: relative;
    z-index: 3;
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    padding: 0;
    border: none;
    border-radius: 50%;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    &:hover {
        text-decoration: none;

        span.dot .bg {
            background-color: #B5365F;
        }
    }

    svg.ring {
        position: absolute;
        z-index: 2;
        left: calc(50% - 65px);
        top: calc(50% - 65px);
        display: block;
        width: 130px;
        height: 130px;
        text-indent: 200%;
        white-space: nowrap;
        overflow: hidden;
        pointer-events: none;
        transition: border-color 0.35s;
    }

    svg.icon {
        position: absolute;
        z-index: 2;
        left: calc(50% - 8px);
        top: calc(50% - 8px);
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        color: $white;
    }

    span.dot {
        position: absolute;
        z-index: 1;
        left: calc(50% - 20px);
        top: calc(50% - 20px);
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        pointer-events: none;
        overflow: hidden;
        background-color: $black;
    }

    h1 {
        position: absolute;
        z-index: 3;
        left: 0;
        top: calc(50% - 15px);
        display: none;
        width: 100%;
        height: 30px;
        margin: 0 !important;
        text-transform: uppercase;
        text-align: center;
        font-family: forward;
        font-size: 16px;
        line-height: 1.875;
        color: $white;
        white-space: nowrap;
        pointer-events: none;
        overflow: hidden;
        opacity: 0;
    }

    @include lg {
        position: fixed;
        top: calc(50% - 150px);
        right: -90px;

        h1 {
            display: block;
        }
    }
}

.dingleberries-wrap {
    perspective: 400px;
}

.dingleberries {
    position: relative;
    width: 100%;
    padding-top: 8%;
    transform-style: preserve-3d;

    img,
    .texture {
        position: absolute;
        opacity: 0;
        transform-style: preserve-3d;
    }

    img:nth-of-type(1) {
        left: 22%;
        top: 45%;
        width: 7%;
        transform: translateZ(150px);
    };

    img:nth-of-type(2) {
        left: -10%;
        top: 10%;
        width: 5%;
        transform: translateZ(-300px);
    };

    img:nth-of-type(3) {
        left: 23%;
        top: -60%;
        width: 20%;
        transform: translateZ(40px);
    };

    img:nth-of-type(4),
    .texture {
        left: 50%;
        top: 0%;
        width: 35vw;
        height: 7vw;
        transform: translateZ(200px);
        background-color: #4F97CB;

        svg {
            @include fill;
        }
    };
}

section.team {
    min-height: calc(100vh - 300px);
    position: relative;
    background-color: $chill-blue;

    .bg {
        min-height: 100vh;
        @include fill;
        z-index: 1;
        background-color: $chill-blue;
    }

    .team-content {
        position: relative;
        z-index: 2;
        padding: 200px 0 0;
        overflow: hidden;
        color: $white;

        h2 {
            margin-bottom: 0;
        }

        h2,
        h3 {
            color: $mid-yellow;
        }
    }

    @include lg {
        .team-content {
            padding: 200px 0;
        }

        .container {
            width: calc(100% - 250px);
        }
    }

    @include xl {
        .container {
            max-width: 960px;
        }
    }
}

.doodads-wrap {
    perspective: 800px;
}

.doodads {
    position: relative;
    width: 100%;
    padding-top: 12%;
    transform-style: preserve-3d;

    h1 {
        display: none;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        transform: translate3d(0, 50%, 300px) scale(0.5);

        &.active {
            transform: translate3d(0, 0, 300px) scale(0.5) !important;
        }
    }

    img,
    .texture {
        position: absolute;
        max-width: none;
        opacity: 0;
    }

    img:nth-of-type(1) {
        left: 20%;
        bottom: -50px;
        width: 100%;
        transform: translateZ(-50px);
    };

    img:nth-of-type(2),
    .texture {
        left: 30%;
        top: 0;
        width: 25%;
        transform: translateZ(250px);
        background-color: #DCDF51;
    };
}

section.culture {
    min-height: calc(100vh - 300px);
    position: relative;
    background-color: $white;

    .bg {
        @include fill;
        z-index: 1;
        height: 580px;
        transform-origin: 0 0;
        background-color: $deep-red;
    }

    h1 {
        position: relative;
        margin: -60px 0 60px;
        color: white;
    }

    .culture-content {
        position: relative;
        z-index: 2;
        padding: 200px 0 0;
        overflow: hidden;
        opacity: 0;
    }

    .culture-office {
        position: relative;
        z-index: 1;
    }

    .image-wrap {
        position: relative;
        margin-top: $spacer * 3;

        .bg {
            @include fill;
            z-index: -1;
            transform: translate(-50px, -50px);
            background-color: $white;
        }
    }

    @include sm {
        .bg {
            height: 720px;
        }
    }

    @include md {
        .bg {
            height: 800px;
        }
    }

    @include lg {
        .culture-content {
            padding: 200px 0;
        }

        .bg {
            height: 860px;
        }

        .container {
            width: calc(100% - 250px);
        }
    }

    @include xl {
        .container {
            max-width: 960px;
        }
    }
}

.dingdongs-wrap {
    perspective: 400px;
}

.dingdongs {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 12%;
    transform-style: preserve-3d;

    img,
    .texture {
        position: absolute;
        opacity: 0;
        transform-style: preserve-3d;
    }

    img:nth-of-type(1) {
        left: 15%;
        top: -15%;
        width: 5.5%;
        transform: translateZ(150px);
    };

    img:nth-of-type(2) {
        left: -35%;
        bottom: 50%;
        width: 150%;
        max-width: none;
        transform: translateZ(-100px) rotate(15deg);
        transform-origin: 50% 100%;
    };

    .texture {
        left: 65%;
        top: -200%;
        width: 20vw;
        height: 20vw;
        transform: translateZ(100px);

        svg {
            @include fill;
        }
    };
}
