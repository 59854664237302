body.video-active {
    overflow: hidden;
}

section.hero {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: calc(100vh - 75px);
    background-color: $bright-blue;
    perspective: 800px;
    overflow: hidden;

    h1 {
        width: 100%;
        text-transform: uppercase;
        font-weight: $heavy;
        font-size: 48px;
        letter-spacing: 0;
        line-height: 0.8;
        color: $white;
        opacity: 0;

        > div {
            overflow: hidden;
        }
    }

    .video-wrap {
        @include fill;
        z-index: 1;
        background-color: $black;
        transition: background-color 1s;

        video {
            @include fill;
            z-index: 1;
            object-fit: cover;
            opacity: 0;
            transition: opacity 1s;

            &.active {
                opacity: 1;
            }
        }

        .logo {
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 45vw;
            height: 75vh;
        }
    }

    .hero-container {
        position: relative;
        z-index: 2;
        transform: translateZ(40px);
        max-width: none;
        padding-left: 10vw;
    }

    .hero-wrap {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
    }

    .social-container {
        position: relative;

        .social {
            position: absolute;
            z-index: 1;
            left: 25px; // Half the logo width to align
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            &::after {
                content: " ";
                display: inline-block;
                width: 3px;
                height: 20px;
                margin-top: 8px;
                background-color: $other-blue;
            }

            a {
                display: block;
                padding: 8px;
                text-transform: uppercase;
                font-weight: $heavy;
                font-size: 12px;
                color: $other-blue;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    button.play {
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 0;
        width: 240px;
        height: 240px;
        padding: 0;
        transform: translateZ(60px);
        background: transparent;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transform-style: preserve-3d;

        > * {
            opacity: 0;
        }

        span.watch {
            position: absolute;
            z-index: 2;
            left: calc(50% - 55px);
            top: calc(50% - 55px);
            display: block;
            width: 110px;
            height: 110px;
            pointer-events: none;

            &::before {
                content: " ";
                position: absolute;
                left: 2px;
                top: 2px;
                display: block;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border: 1px solid $white;
                border-radius: 50%;
            }
        }

        span.play {
            position: absolute;
            z-index: 1;
            left: calc(50% - 70px);
            top: calc(50% - 70px);
            display: block;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            pointer-events: none;
            transition: background-color 0.25s;
            overflow: hidden;

            .icon {
                content: " ";
                position: absolute;
                z-index: 2;
                left: calc(50% - 15px);
                top: calc(50% - 15px);
                transform: translateY(-15px);
                display: block;
                width: 30px;
                height: 30px;
                margin: 0;
                color: $black;
                transition: color 0.25s;
            }

            .bg {
                position: absolute;
                z-index: 1;
                left: -100px;
                top: -100px;
                width: calc(100% + 200px);
                height: calc(100% + 200px);
                background-image: url('../img/delta-pattern.jpg');
                background-color: $chill-yellow;
                background-blend-mode: screen;
                background-size: cover;
                transition: background-color 0.25s;
            }
        }

        span.text {
            position: absolute;
            z-index: 3;
            left: 0;
            top: calc(50% - 15px);
            transform: translateY(15px);
            display: block;
            width: 100%;
            height: 30px;
            text-transform: uppercase;
            text-align: center;
            font-family: forward;
            font-size: 12px;
            line-height: 1.875;
            color: $black;
            white-space: nowrap;
            transition: color 0.25s;
        }

        &:hover,
        &:active,
        &:focus {
            outline: none;

            span.play .bg {
                background-color: #B5365F;
            }

            span.watch::before {
                border-color: $black;
            }

            span.text {
                color: $white;
            }
        }
    }

    @include sm {
        h1 {
            font-size: 60px;
        }
    }

    @include md {
        h1 {
            font-size: 72px;
        }

        button.play {
            right: 12vw;
            bottom: 6vh;
            width: 300px;
            height: 300px;

            span.watch {
                transform: translate3d(0, 0, 40px);
            }

            span.play .icon {
                transform: translateY(0);
            }

            span.text {
                transform: translate3d(0, 0, 80px);
            }
        }
    }

    @include lg {
        height: 100vh;

        h1 {
            font-size: 98px;
        }
    }
}

.services-heading {
    position: relative;
    z-index: 1;
    width: 150vw;
    height: 11vw;

    img {
        @include fill;
        display: block;
    }

    h1 {
        margin-bottom: 0;
        text-transform: uppercase;
        line-height: 1;
        font-size: 15vw;
        font-weight: $bold;
        white-space: nowrap;
        color: $white;
        text-shadow: -1px -1px 0 #999,
                      1px -1px 0 #999,
                      -1px 1px 0 #999,
                       1px 1px 0 #999;
    }
}

section.services {
    position: relative;
    min-height: 600px;
    padding: 10vw 0;
    background-color: $black;

    .bg {
        @include fill;
        z-index: 1;
        background-color: $white;
    }

    .container {
        position: relative;
        z-index: 2;

        .row {
            position: relative;
            z-index: 2;
        }
    }

    .intro {
        perspective: 800px;

        h2,
        p {
            opacity: 0;

            > div {
                transform-origin: 0 0;
            }
        }
    }

    .service-graphic {
        position: relative;
        padding-top: 100%;

        img,
        video {
            @include fill;
            display: block;
            object-fit: cover;
        }
    }

    .service-description {
        margin: 0 auto;
        padding: 10px 0;
        perspective: 800px;
        background-color: $white;
    }

    @include md {
        .intro p {
            font-size: rem(24px);
        }

        .service-description {
            max-width: 320px;
            padding: 0;
            background-color: transparent;
        }
    }

}
