section.work {
    min-height: 100vh;
    background-color: $deep-red;
    color: $black;
    overflow: hidden;

    > * {
        opacity: 0;
    }

    a {
        color: $mid-yellow;
    }
}

.project {
    margin-bottom: 40px;
}

.grid-image {
    position: relative;

    .caption {
        perspective: 800px;

        p {
            max-width: 300px;
            margin: 20px;

            > div {
                transform-origin: 0 0;
            }
        }
    }

    .img-wrap {
        overflow: visible;

        img {
            display: block;
            width: 100%;
        }
    }

    @include md {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-bottom: -30px;

        .caption {
            width: 50%;

            p {
                margin: 0 auto;
                padding: 0 20px;
            }
        }

        .img-wrap {
            width: 50%;

            img {
                max-width: none;
                width: calc(100% + 50px);
            }
        }

        &.even {
            flex-flow: row-reverse;
        }

        &.odd .img-wrap img { left: auto; right: 0; }
    }

    @include lg {
        .caption p { padding: 0 40px; }
    }
}

.work-image {
    @include md {
        &.odd .caption p { margin-right: 150px; }
        &.even .caption p { margin-left: 150px; }
    }

    @include lg {
        &.odd .caption p { margin-right: 225px; }
        &.even .caption p { margin-left: 225px; }
    }

    @include xl {
        &.odd .caption p { margin-right: 300px; }
        &.even .caption p { margin-left: 300px; }
    }
}

.project-headings {
    position: fixed;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 150px;
    cursor: pointer;

    &::before {
        content: " ";
        @include fill;
        z-index: 10;
        background-color: rgba(0,0,0,0.35);
        opacity: 0;
        transition: opacity 0.25s;
        pointer-events: none;
    }

    &.hover {
        &::before {
            opacity: 1;
        }

        .btn {
            opacity: 1;
            transform: translate(-50%, -50%);
            transition: opacity 0.25s, transform 0.75s $ease-out;
        }
    }

    .btn {
        position: absolute;
        z-index: 11;
        left: 50%;
        top: 50%;
        transform: translate(-50%, calc(-50% + 10px));
        transition: opacity 0.5s, transform 0.75s $ease-in;
        margin-top: 0;
        opacity: 0;
        color: $white;
        pointer-events: none;
        white-space: nowrap;
    }

    .icon {
        position: absolute;
        right: 30px;
        top: calc(50% - 10px);
        display: block;
        width: 20px;
        height: 20px;
        margin: 0;
        opacity: 0;
        color: $white;
    }

    @include md {
        left: 50vw;
        top: 50%;
        bottom: auto;
        width: 320px;
        transform: translate(-50%, -50%);
    }

    @include lg {
        width: 450px;
        height: 200px;
    }

    @include xl {
        width: 520px;
    }
}

.project-heading {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(-50%);
    padding: 20px;
    color: $white;

    &.active {
        z-index: 1;
    }

    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
    }

    h1 {
        margin: 0;
        text-transform: uppercase;
        font-weight: $heavy;
        line-height: 0.9;
        letter-spacing: 0;

        > div {
            overflow: hidden;
        }
    }

    p {
        font-size: 14px;
        line-height: 1;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p.tags {
        span {
            position: relative;
            display: inline-block;
            margin: 0 3px 5px 0;
            padding: 3px 5px;
            font-weight: $medium;
            font-size: 12px;
            text-transform: uppercase;
            background: rgba(255,255,255,0.25);
            border-radius: 2px;
            color: $black;
        }
    }

    @include md {
        padding: 20px 30px;

        h1 {
            font-size: 32px;
        }
    }

    @include lg {
        padding: 40px;

        h1 {
            font-size: 38px;
        }

        p {
            font-size: 16px;
        }
    }
}

.work-overlay {
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: $dark-red;
    transform-origin: 50% 100%;

    @include md {
        transform-origin: 50% 50%;
    }
}

section.work-hero {
    position: relative;
    z-index: 10;
    padding-top: calc(10vw + #{$spacer*2});
    padding-bottom: calc(10vw + #{$spacer});
    font-weight: $light;
    font-size: rem(18px);
    color: $white;

    h1 {
        margin-bottom: 2rem;
    }

    h1 {
        margin-bottom: 2rem;
    }

    @include md {
        font-size: rem(24px);
    }
}

section.work-content {
    position: relative;
    opacity: 0;

    .bg {
        @include fill;
        z-index: 1;
        background-color: $white;
    }

    > div:not(.bg) {
        position: relative;
        z-index: 2;
    }
}

.image-grid {
    .image-grid__content {
        padding: 25px;
    };

    .container .image-grid__content {
        padding: 25px 0;
    };

    @include md {
        .image-grid__content {
            margin: auto;
            padding: 25px;
        };

        .container {
            .image-grid__content {
                margin-left: 0;
                margin-right: auto;
                padding-right: 25px;
            }

            .order-md-2 .image-grid__content {
                margin-left: auto;
                margin-right: 0;
                padding-left: 25px;
            }
        }
    }

    @include lg {
        .image-grid__content {
            width: 360px;
        };
    }
}

.video-player {
    position: relative;
    background-color: $black;

    .play-wrap {
        position: absolute;
        z-index: 3;
        left: calc(50% - 150px);
        top: calc(50% - 150px);
        display: block;
        width: 300px;
        height: 300px;
    }

    .play {
        position: absolute;
        z-index: 3;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        display: block;
        width: 100px;
        height: 100px;
        background-color: $mid-yellow;
        border-radius: 50%;
        overflow: hidden;

        .icon {
            content: " ";
            position: absolute;
            z-index: 2;
            left: calc(50% - 12px);
            top: calc(50% - 15px);
            display: block;
            width: 30px;
            height: 30px;
            margin: 0;
            color: $black;
        }

        .bg {
            position: absolute;
            z-index: 1;
            left: -100px;
            top: -100px;
            width: calc(100% + 200px);
            height: calc(100% + 200px);
            background-image: url('../img/delta-pattern.jpg');
            background-color: $chill-yellow;
            background-blend-mode: screen;
            background-size: cover;
            transition: background-color 0.25s;
        }
    }

    .player {
        @include fill;
        z-index: 2;
    }

    .thumbnail {
        position: relative;
        z-index: 1;
    }

    @include md {
        .play {
            left: calc(50% - 75px);
            top: calc(50% - 75px);
            width: 150px;
            height: 150px;

            .icon {
                left: calc(50% - 17px);
                top: calc(50% - 20px);
                width: 40px;
                height: 40px;
            }
        }
    }
}

a.next-project {
    position: relative;
    display: inline-block;
    margin: 80px 0;
    transition: color 0.25s, text-shadow 0.25s;
    color: white;

    svg {
        overflow: visible;
        max-width: 100%;
    }

    path,
    polygon {
        fill: $white;
        stroke: #999;
        transition: fill 0.25s, stroke 0.25s;
    }

    &:hover {
        color: currentColor;

        path,
        polygon {
            fill: currentColor;
            stroke: currentColor;
        }
    }
}

.next-project-overlay {
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
