@font-face {
    font-family: 'forward';
    src: url('../fonts/fffforwa-webfont.woff2') format('woff2'),
         url('../fonts/fffforwa-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: $sans;
    line-height: 1.5;
    overscroll-behavior-y: none;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
    font-weight: $regular;

    &:last-child {
        margin-bottom: 0;
    }
}

h1.pixel {
    margin-bottom: 1.5rem;
    font-size: rem(20px);
    line-height: 1.5;
    letter-spacing: 0.1em;
    font-family: $pixel;
    font-weight: $regular;

    @include md {
        font-size: rem(26px);
    }

    @include lg {
        font-size: rem(32px);
    }
}

h1, .h1 {
    font-size: rem(32px);
    font-weight: normal;

    @include md {
        font-size: rem(40px);
    }

    @include lg {
        font-size: rem(48px);
    }
}

h2, .h2 {
    font-size: rem(24px);
    font-weight: normal;

    @include md {
        font-size: rem(30px);
    }

    @include lg {
        font-size: rem(36px);
    }
}

h3, .h3 {
    font-size: rem(20px);
    font-weight: normal;

    @include md {
        font-size: rem(25px);
    }

    @include lg {
        font-size: rem(30px);
    }
}

h4, .h4 {
    font-size: rem(18px);
    font-weight: normal;

    @include md {
        font-size: rem(21px);
    }

    @include lg {
        font-size: rem(24px);
    }
}

a,
a:hover {
    color: $purple;
}

pre {
    padding: 5px 10px;
    background-color: $gray-100;
    border-radius: 3px;
    color: $gray-800;
}

.btn {
    display: inline-block;
    margin-top: 0.5rem;
    padding: 1rem 2rem;
    font-size: rem(15px);
    letter-spacing: 1px;
    color: $white;
    background-color: $black;
    border-radius: 3rem;
    transition: background-color 0.25s;

    &:hover {
        color: $white;
        background-color: $dark-grey;
        text-decoration: none;
    }
}

svg.icon {
    width: 0.9em;
    height: 0.9em;
    margin: 0 0.5rem;
    fill: currentColor;
    vertical-align: baseline;
}
